<template>
  <CommonTeleportModal @cancel="close">
    <div class="activity-renew-modal-vip" v-show="isShow">
      <img @click="close" class="activity-renew-modal-vip-close" src="https://qncweb.ktvsky.com/20230901/vadd/bb4c5c9684cfc9526bcfbec0a1ee2402.png">
      <img class="activity-renew-modal-vip-top-img" src="https://qncweb.ktvsky.com/20230901/vadd/081fccee1059d5da84b21fd154479354.png" alt="">
      <img class="activity-renew-modal-vip-title-img" src="https://qncweb.ktvsky.com/20230901/vadd/627b32fec2e0b75cc7179ba143ab792f.png" alt="">
      <div class="activity-renew-modal-vip-bottom">
        <div class="activity-renew-modal-vip-code">
          <img :src="qrCodeURL" alt="">
          <span>立即扫码购买</span>
        </div>
      </div>
    </div>
  </CommonTeleportModal>
</template>
<script>
import { ref, onBeforeMount } from 'vue'
import CommonTeleportModal from './../common/index.vue'
import { getCarplayInfo } from '@/service/carplay-info'
import useQRCode from '@/composables/useQRCode'
import Toast from '@/utils/toast'
import get from 'lodash/get'

export default {
  name: 'ActivityRenewVipModal',
  components: {
    CommonTeleportModal,
  },
  setup(_, { emit }) {

    const { getQRCodeURL } = useQRCode()
    let qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png')

    const getVipQrcode = async () => {
      const { data } = await getCarplayInfo()
      if (get(data, 'pay_qr', '')) {
        const qrCodeData = await getQRCodeURL(`${data.pay_qr}&renew=1`) // 对应移动端文案
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData
        }
        return
      }
      Toast('未获取到登录二维码')
    }

    onBeforeMount(getVipQrcode)

    let isShow = ref(true)

    const show = () => {
      isShow.value = true
    }

    const close = () => {
      isShow.value = false
      emit('close')
    }

    return {
      qrCodeURL,
      isShow,
      show,
      close,
    }
  }
}
</script>
<style lang="stylus" scoped>
.activity-renew-modal-vip
  padding-top 122px
  position relative
  width 1000px
  height 730px
  border-radius 20px
  background linear-gradient(159deg, #585450 -44.7%, #1E1F21 91.57%)
  color rgba(255, 51, 100, 1)
  display flex
  flex-direction column
  align-items center
  &-close
    width 90px
    height 90px
    position absolute
    top 30px
    left 30px
  &-top-img
    width 290px
    height 164px
    position absolute
    top -78px
    left 355px
  &-title-img
    width 767px
    height 157px
    position absolute
    top 135px
    left 121px
  &-bottom
    width 100%
    height 300px
    display flex
    justify-content center
    position absolute
    bottom 80px
    left 0
  &-code
    width 250px
    height 300px
    background #fff
    border-radius 8px
    display flex
    flex-direction column
    align-items center
    img
      width 230px
      height 230px
      margin-top 10px
    span
      height 34px
      line-height 34px
      font-weight 400
      font-size 24px
      color rgba(0, 0, 0, 0.8)
      margin-top 12px
</style>
