<template>
  <div class="record">
    <div class="record-title">兑换记录</div>
    <div class="record-list">
      <LoadMore
        v-if="records.length"
        @load-more="fetchData"
        safeAreaHeight="12.6991vw"
      >
        <div class="record-item" v-for="(item, index) in records" :key="index">
          <div class="left">
            <h3>{{ item.pkg_desc }}</h3>
            <p>{{ item.create_time }}</p>
          </div>
          <div class="right">{{ item.days }} 天</div>
        </div>
        <div class="record-bottom">
          <p v-if="records.length > 10">已加载全部</p>
        </div>
      </LoadMore>
      <div v-else-if="!isRequest" class="record-empty">暂无兑换记录</div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed } from 'vue'
import { useStore } from 'vuex'
import { getExchangeHistory } from '@/service/vip'

export default {
  name: 'Record',
  setup() {
    const store = useStore()
    const userInfo = computed(() => store.state.userInfo)

    let records = ref([])
    let p = 1
    let isRequest = false

    const handleGetExchangeHistoryList = async () => {
      if (isRequest) {
        return
      }
      isRequest = true

      const historyResponseData = await getExchangeHistory(
        userInfo.value.unionid,
        p
      )
      if (historyResponseData.length) {
        records.value = records.value.concat(historyResponseData)
        p++
      }
      isRequest = false
    }

    onBeforeMount(handleGetExchangeHistoryList)

    return {
      records,
      handleGetExchangeHistoryList,
    }
  },
}
</script>

<style lang="stylus" scoped>
.record
  &-title
    padding 100px 0 25px
    margin-bottom 28px
    font-size: 32px;
    color rgba(255, 255, 255, 0.6)
    border-bottom 2px solid rgba(255, 255, 255, 0.1)
    @media screen and (max-width 1200px)
      padding-top 200px
      font-size 26px
  .record-item
    display flex
    justify-content space-between
    align-items center
    height 117px
    h3
      font-size 32px
      color rgba(255, 255, 255, 0.7)
    p
      font-size 24px
      color rgba(255, 255, 255, 0.4)
    .right
      font-size: 40px;
      color: rgba(255, 255, 255, 0.80)
    @media screen and (max-width 1200px)
      h3
        font-size 26px
      p
        font-size: 20px;
      .right
        font-size: 26px;
  &-bottom
    margin-top 22px
    color rgba(255, 255, 255, 0.4)
    font-size 28px
    text-align center
  &-empty
    width 150px
    color rgba(255, 255, 255, 0.2)
    font-size: 24px;
    background url('https://qncweb.ktvsky.com/20231208/other/1875593d63cfd422ca6fd1ea36dddf56.svg') no-repeat
    background-size 72px 72px
    background-position top center
    padding 100px 0 188px
    margin 136px auto 0
    text-align center
</style>

